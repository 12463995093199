import { StockIndices, type StockIndicesSymbol } from "../types/stocks-depot"
import { MappedStocksPortfolioType, PortfolioType, mapListToTypedCards } from "../types/stocks-portfolios"

export interface StockServiceCardItem {
  name: string
  header?: string
  description?: string
  shortDescription?: string
  image: string
  id: string
  to?: TypedRouteProps | string
}

export function useStockIndexServices(
  options?: Partial<{
    enabled: MaybeRef<boolean>
    stockIndex?: Ref<StockIndicesSymbol>
  }>
) {
  const { t, te } = useI18n()
  const query = useStocksPortfoliosTypes({
    enabled: options?.enabled ?? true,
  })

  const getExistingLocalePath = (localePath: string, item: MappedStocksPortfolioType) => {
    const path = `${localePath}.${item.id}`
    return te(path) ? t(path) : undefined
  }

  const types = computed(() => {
    if (query.isError.value || !query.isFetched.value) {
      return arrayInLength(3).map((i: number) => ({ id: String(i) })) as MappedStocksPortfolioType[]
    }

    const list = mapListToTypedCards(query.data.value) as MappedStocksPortfolioType[]

    return list
      .map((item) => ({
        ...item,
        name: getExistingLocalePath("stocks.portfolios.types", item),
        description: getExistingLocalePath("stocks.service_cards.descriptions", item),
        shortDescription: getExistingLocalePath("stocks.service_cards.short_descriptions", item),
      }))
      .slice(0, -1)
  })

  return { query, types }
}

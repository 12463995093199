<template>
  <StocksPortfoliosTypeCard
    :item
    full
    :loading="!query.isFetched.value"
    :error="query.isError.value"
    :variant
    size="387"
    :classes="{
      root: [className, 'z-1'],
    }"
    :attributes
    :short-description
  >
    <template #footer>
      <div
        v-if="query.isFetched.value"
        :class="cn('flex w-full flex-col gap-2 text-sm', { 'text-xs': variant === 'small' })"
      >
        <div
          v-for="stockData in generateStockYieldsData(item)"
          :key="stockData.key"
          :class="cn('relative flex w-full items-center justify-between whitespace-pre last:pt-2')"
        >
          <div
            class="dotted-divider absolute inset-x-0 top-0 z-50 opacity-40"
            v-if="stockData.id === 'overperformance'"
          ></div>

          <UiSkeleton
            :classes="{
              slot: 'reverse md:gap-xxs prose:leading-none flex md:flex-row md:items-center',
              line: 'w-l',
            }"
            :loading="!query.isFetched.value"
          >
            <span :class="cn('font-light text-white', { 'font-medium': stockData.id === 'overperformance' })">
              {{ stockData.key }}

              <span
                v-if="stockData.id === 'overperformance'"
                :class="
                  cn('text-xxs ms-px font-light leading-none', {
                    'text-[8px]': variant === 'small',
                  })
                "
              >
                {{ t("since_inception") }}
              </span>
            </span>
          </UiSkeleton>

          <UiSkeleton
            :loading="!query.isFetched.value"
            :classes="{
              slot: 'md:gap-xxs prose:leading-none flex flex-col-reverse md:flex-row md:items-center',
              line: 'w-l',
            }"
          >
            <UiPositiveNegative
              :classes="{ success: 'text-[#24DB5F]', span: 'font-medium' }"
              :value="stockData.value || 0"
              :format="(v) => toPercent(v, false, 2)"
            />
          </UiSkeleton>
        </div>
      </div>
    </template>
  </StocksPortfoliosTypeCard>
</template>

<script setup lang="ts">
import { ServiceCardProps } from "@finq/app-base/components/shared/ServiceCard/ServiceCard.vue"

import {
  type MappedStocksPortfolioType,
  type StocksPortfolioTypeWithAdditional,
} from "@finq/stocks/types/stocks-portfolios"

const props = withDefaults(
  defineProps<{
    item: MappedStocksPortfolioType
    className?: string
    variant?: ServiceCardProps["variant"]
    attributes?: ServiceCardProps["attributes"]
    shortDescription?: boolean
  }>(),
  { variant: "default" }
)

const { t } = useI18n({ useScope: "local" })
const { t: $t } = useI18n({ useScope: "global" })
const { query } = useStockIndexServices()

function generateStockYieldsData(stock: StocksPortfolioTypeWithAdditional) {
  const { id, comparedPortfolioId, accumulatedYield, comparedAccumulatedYield } = stock
  if (!id) return []
  return [
    {
      id,
      key: $t("stocks.portfolios.types." + id),
      value: accumulatedYield,
    },
    {
      id: comparedPortfolioId,
      key: $t("stocks.portfolios.types." + comparedPortfolioId),
      value: comparedAccumulatedYield || 0,
    },
    {
      id: "overperformance",
      key: t("overperformance"),
      value: (accumulatedYield || 0) - (comparedAccumulatedYield || 0),
    },
  ]
}
</script>

<style lang="scss" scoped>
.dotted-divider {
  height: 1px;
  background-image: linear-gradient(to right, currentColor 1px, transparent 1px);
  background-size: 4px 1px;
  background-repeat: repeat-x;
}
</style>

<i18n lang="json">
{
  "en": {
    "overperformance": "Overperformance",
    "since_inception": "since inception"
  },
  "he": {
    "overperformance": "ביצועי יתר",
    "since_inception": "מתאריך ההקמה"
  }
}
</i18n>
